<template>
  <div>
    <Managelicenses />
  </div>
</template>
<script>
import Managelicenses from "@/components/licenses/Managelicenses";
export default {
  components: {
    Managelicenses,
  },
  created() {
  },
};
</script>