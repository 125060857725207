<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #FF0000">จัดการใบประกอบวิชาชีพ</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark>เพิ่มใบประกอบวิชาชีพ</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headerslicenses"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.headerImg`]="{ item }">
              <v-img :src="item.headerImg" width="120px" class="ma-1"></v-img>
            </template>
            <!-- <template v-slot:[`item.description`]="{ item }">
              <span v-html="item.description"></span>
            </template> -->
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.activeFlag == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="Viewlicenses(item)">mdi-eye</v-icon> -->
                <v-icon @click="Updatelicenses(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="Deletelicenses(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headerslicenses: [
        { text: "ลำดับ", value: "count", align: "center" },
        // { text: "รูปภาพ", value: "headerImg", align: "center" },
        // { text: "รายละเอียด", value: "shortDesc", align: "center" },
        { text: "ชื่อใบประกอบวิชาชีพ", value: "name", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAlllicenses();
  },
  methods: {
    async getAlllicenses() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/licenses`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAlllicenses", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    Updatelicenses(val) {
      localStorage.setItem("licensesdata", Encode.encode(val));
      this.$router.push("Editlicenses");
    },
    Viewlicenses(val) {
      localStorage.setItem("licensesdata", Encode.encode(val));
      this.$router.push("Viewlicenses");
    },
    async Deletelicenses(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userHealthAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/licenses/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAlllicenses();
        }
      });
    },
    goToCreate() {
      this.$router.push("createlicenses");
    },
  },
};
</script>
